@use 'stylesheets/setup' as *;

@use 'stylesheets/components/sales/admin_sale_nav';
@use 'stylesheets/components/sales/buy_cluster';

main#offer-page {
  .offer-masthead {
    position: relative;
    padding: calc(var(--header-nav-height) - 2rem) 0 1rem;
    background: palette(black, light);
    color: palette(white);
    z-index: 0;

    @include slice-from(md) {
      padding-bottom: 3rem;
    }

    @include masthead-background();

    .masthead-contents {
      position: relative;
    }

    h1.offer-heading {
      margin: 2rem 0 1rem;
      font-weight: normal;

      .accessory-badge {
        color: palette(white);
        font-size: 0.9rem;
        border-radius: 25px;
        border-color: palette(red);
        background-color: palette(red);
        padding: 7px 12px;
        text-transform: uppercase;
        margin-bottom: 5px;
        width: fit-content;
        cursor: pointer;
      }

      .producer {
        display: block;
        color: palette(gray);
        font-size: 1.5rem;
      }

      .name {
        display: block;
        font-weight: 400;
      }
    }

    .metadata {
      margin: 0;

      @include slice-from(xs) {
        margin: 1rem 0 0;

        &.with-stacked-photo {
          margin: 3rem 0 0;
        }
      }

      @include slice-from(sm) {
        margin: 1rem 0;

        &.with-stacked-photo {
          margin: 3rem 0 1rem;
        }
      }

      @include slice-from(md) {
        margin: 1rem 0;

        &.with-stacked-photo {
          margin: 0;
        }
      }

      .meta-row {
        display: flex;
        align-items: center;
        margin: 0 0 0.5rem;

        @media (max-width: 480px) {
          font-size: 0.9rem;
        }

        .icon {
          flex: 0 0 auto;
          display: inline-block;
          margin-right: 0.5rem;
          width: 1rem;
          height: 1rem;
        }

        .text {
          display: inline-block;
        }
      }
    }

    .offer-award {
      position: relative;
      padding: 0.75rem 1rem;
      margin: 1rem 0;
      background: palette(black);
      border-radius: vars(border-radius);
      overflow: hidden;
      z-index: 1;

      .award-quotes {
        display: none;
        position: absolute;
        top: 0;
        right: -1rem;
        z-index: -1;

        svg {
          height: 4rem;
        }

        @media (min-width: 480px) {
          display: block;
        }

        @include slice-from(md) {
          right: 1rem;
        }
      }

      .name {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      .category {
        font-size: 0.9rem;
        font-weight: 700;
        color: palette(red, bright);
      }
    }

    .offer-profile {
      margin: 1rem 0;

      .profile-row {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        .profile-cell {
          flex: 0 0 auto;
          display: block;
          width: 9px;
          height: 9px;
          margin-right: 2px;
          border: 1px solid palette(white);
          border-radius: 1rem;

          &.active {
            border-color: palette(red, bright);
            background: palette(red, bright);
          }
        }

        .label {
          margin-left: 0.25rem;
          text-transform: uppercase;
          font-size: 0.65rem;
          line-height: 10px;
          font-weight: 500;
          opacity: 0.5;
        }
      }

      @media (min-width: 480px) {
        .profile-row {
          margin-bottom: 0.25rem;

          .profile-cell {
            width: 10px;
            height: 10px;
            margin-right: 0.25rem;
            border: 2px solid palette(white);
          }

          .label {
            margin-left: 0.5rem;
            font-size: 0.75rem;
            line-height: 10px;
            font-weight: 700;
          }
        }
      }
    }

    .offer-badges {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0.5rem 0;

      @include slice-from(md) {
        margin: 1rem 0;
        justify-content: flex-end;
        min-height: 4rem;
      }

      .offer-badge {
        display: flex;
        flex-direction: row-reverse;
        background: palette(black);
        color: palette(white);
        border-radius: 2rem;
        line-height: 1.6rem;
        font-size: 0.8rem;
        font-weight: 700;
        margin-right: 0.5rem;

        .name {
          display: none;
          padding: 0 1rem 0 0.5rem;
        }

        .score {
          padding: 0 0.5rem;
          min-width: 1.6rem;
          background: palette(red);
          text-align: center;
          border-radius: 1.6rem;
        }

        @media (min-width: 480px) {
          margin: 0.25rem 0;
          margin-right: 1rem;
          line-height: 2rem;

          .name {
            display: block;
          }

          .score {
            min-width: 2rem;
            border-radius: 2rem;
          }
        }

        @include slice-from(md) {
          flex-direction: row;
          margin: 0.25rem 0;
          margin-left: 1rem;
          margin-right: 0;
          line-height: 2rem;

          .name {
            display: block;
            padding: 0 0.5rem 0 1rem;
          }
        }
      }
    }

    .masthead-summary-column {
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }

    .masthead-thumbnail-column {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include slice-between(xs, sm) {
      .masthead-thumbnail-column {
        align-items: flex-end;
      }
    }

    @include slice-from(md) {
      .masthead-thumbnail-column {
        justify-content: center;
      }
    }

    .mobile-offer-award-column {
      margin-bottom: -3rem;
    }

    .featured-sale-thumbnail {
      position: relative;
      margin: 1rem 0;
      z-index: 101;
    }

    // limit featured image width to prevent x overflow
    .featured-sale-thumbnail {
      .stacked-photo {
        .bottle-shot {
          width: 80%;
          overflow: hidden;

          .bottle-photo {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    @include slice-between(xs, sm) {
      .featured-sale-thumbnail {
        width: 100%;
        max-width: 12rem;

        .stacked-photo {
          width: 100%;
          margin: 2rem 0;

          .image-backdrop {
            position: relative;
            width: 100%;
            height: auto;
            max-width: 12rem;
            max-height: 12rem;
            padding-top: 100%;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }

          .bottle-shot {
            height: calc(100% + 4rem);

            img.bottle-photo {
              height: 100%;
            }
          }
        }

        .legacy-photo {
          width: 100%;
          max-width: 12rem;

          img {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 419px) {
      h1.offer-heading {
        margin: 1rem 0;
        font-size: 1.5rem;

        .accessory-badge {
          font-size: 0.75rem;
          padding: 5px 10px;
        }

        .producer {
          font-size: 1.2rem;
        }
      }

      .offer-award {
        margin: 1.5rem 0 0.5rem;
        padding: 0.75rem 1rem;

        .name {
          margin-bottom: 0.25rem;
          font-size: 1rem;
        }

        .category {
          font-size: 0.9rem;
        }
      }

      .offer-profile {
        margin: 0.5rem 0;
      }
    }

    // style bottle zoom controls
    .bottle-zoom-overlay-toggle {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      svg {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        color: palette(white, base, 50%);
      }
    }

    @include slice-between(xs, sm) {
      .thumbnail-stacked ~ .bottle-zoom-overlay-toggle {
        top: 2rem;
        bottom: 2rem;
      }
    }

    .bottle-zoom-toggle {
      display: block;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      margin: 0;
      font-size: 1.25rem;
      color: palette(white);
      background: palette(black, base, 0%);
      border: none;
      text-shadow: 0 2px 2px palette(black) !important;
      opacity: 0;
      transform: scale(0.75);
      transition: all 250ms $ease-out-quint;
      cursor: pointer;
      z-index: 1;

      &:focus::after {
        border-color: palette(black, base, 25%);
      }

      &:hover, &:focus {
        background: palette(black, base, 25%);
      }
    }

    .featured-sale-thumbnail:hover .bottle-zoom-toggle,
    .bottle-zoom-toggle:focus {
      opacity: 1;
      transform: none;
    }
  }
    // style bottle zoom modal
    .ui-modal-root.bottle-zoom-modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: palette(black, base, 50%);
      z-index: 1000;

      .ui-modal {
        background: none;
        box-shadow: none;
      }

      .modal-header {
        background: none;

        .modal-toggle {
          margin: 0.5rem;
          padding: 1rem;
          background: palette(black, base, 75%);
          border-radius: 50%;

          svg {
            color: palette(white);
          }
        }
      }

      .modal-body {
        overflow: hidden;
      }

      .zoom-thumbnail {
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .ui-sale-thumbnail {
          width: 100%;

          &.thumbnail-stacked {
            margin-bottom: 2rem;
          }
        }

        .stacked-photo {
          width: 100%;
          margin: 2rem 0;

          .image-backdrop {
            position: relative;
            width: 100%;
            height: auto;
            padding-top: 100%;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }

          .bottle-shot {
            height: calc(100% + 4rem);

            img.bottle-photo {
              height: 100%;
            }
          }
        }

        @include slice-to(xs) {
          .stacked-photo {
            width: 100%;
            height: calc(100vw - 4rem);
            margin: 2rem 0;

            .image-backdrop {
              position: absolute;
              left: -2rem;
              right: -2rem;
              bottom: -4rem;
              height: calc(100% + 2rem);
              width: calc(100% + 4rem);
              max-width: none;
              max-height: none;
              overflow: visible;

              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: vars(border-radius, large) vars(border-radius, large) 0 0;
              }
            }

            .bottle-shot {
              height: calc(100% + 4rem);

              img.bottle-photo {
                height: 100%;
              }
            }
          }
        }

        .legacy-photo {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }
    }

  article.offer-detail {
    padding-bottom: 2rem;

    .sections {
      margin: 0 0 3rem;

      section {
        position: relative;
        padding: 1rem 0;

        &:first-child .section-heading {
          color: palette(red);
        }

        &:nth-child(1) .section-heading::before { content: "01"; }
        &:nth-child(2) .section-heading::before { content: "02"; }
        &:nth-child(3) .section-heading::before { content: "03"; }
        &:nth-child(4) .section-heading::before { content: "04"; }

        .section-heading {
          position: sticky;
          top: 0;
          margin: 2.5rem 0 0;
          padding: 1rem 0;
          padding-right: 2rem;
          background: palette(white);
          color: palette(black);
          z-index: 100;

          &::before {
            display: block;
            position: absolute;
            top: -1.5rem;
            left: 0;
            margin: 0 0 0.5rem;
            line-height: 2rem;
            font-weight: 700;
            color: palette(black);
            opacity: 0.25;
          }

          @include slice-from(md) {
            margin: 0;

            &::before {
              position: absolute;
              top: auto;
              bottom: calc(50% - 1rem);
              left: -2.5rem;
              margin: 0;
            }
          }

          small {
            display: block;
            font-family: vars(font-body);
            font-size: 0.75rem;
            color: palette(gray, dark);
            text-transform: uppercase;
          }

          .mobile-caret {
            position: absolute;
            top: 1.5rem;
            right: 0;
            color: palette(gray);
            font-size: 0;
            transform: rotateZ(-180deg);
            transition: all 250ms ease;

            svg {
              width: 1.5rem;
              height: 1.5rem;
            }

            @include slice-from(md) {
              top: 1.5rem;
              right: 1.25rem;
            }
          }
        }

        &[data-accordion-open="false"] .section-heading .mobile-caret {
          opacity: 1;
          transform: none;
        }

        .section-content {
          @include slice-to(sm) {
            &.has-photo {
              min-height: 16rem;
            }
          }

          a {
            color: palette(red);
            @include link-underline(palette(red));
          }

          // bottom spacer
          &::after {
            content: " ";
            display: block;
            width: 100%;
            height: 2rem;
          }

          .gradient-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            max-height: 8rem;
            background: linear-gradient(to bottom, rgba(palette(white), 0), palette(white) 100%);
            opacity: 0;
            transition: all 250ms ease;
            pointer-events: none;
          }
        }

        &[data-accordion-open="false"] .section-content .gradient-overlay {
          opacity: 1;
        }

        .section-photo {
          position: relative;
          margin: 1rem 0;
          font-size: 0;
          border-radius: vars(border-radius);
          overflow: hidden;
          text-align: center;
          transition: all 250ms ease;

          .photo-filler {
            position: absolute;
            top: -25px;
            left: -25px;
            right: -25px;
            bottom: -25px;
            background-position: center center;
            background-size: cover;
            filter: blur(25px);
            opacity: 0.5;
          }
        }

        @include slice-to(sm) {
          .section-thumbnail {
            display: none;
          }
        }

        @include slice-from(md) {
          &[data-accordion-open="false"] .section-thumbnail + .section-photo {
            opacity: 0;
            transform: translateY(-0.5rem);
          }
        }

        + section {
          border-top: 1px solid palette(border);
        }

        .section-thumbnail {
          position: absolute;
          top: 1.25rem;
          right: 0;
          font-size: 0;
          border-radius: vars(border-radius);
          width: 4rem;
          height: 4rem;
          overflow: hidden;
          text-align: center;
          z-index: 100;
          transition: all 250ms ease;
          pointer-events: none;
        }

        &[data-accordion-open="true"] .section-thumbnail {
          opacity: 0;
          transform: translateY(0.5rem);
        }
      }

      section.tasting-profile {
        .label-photo {
          margin: 1rem 0;
          font-size: 0;

          @media (max-width: 279px) {
            display: none;
          }

          img {
            width: 6rem;
            height: 6rem;
            border-radius: vars(border-radius);

            @include slice-from(sm) {
              width: 8rem;
              height: 8rem;
            }
          }
        }

        .offer-profile {
          margin: 2rem 0 0;

          .profile-row {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;

            .profile-cell {
              flex: 0 0 auto;
              display: block;
              width: 0.75rem;
              height: 0.75rem;
              margin-right: 0.5rem;
              border: 2px solid palette(black);
              border-radius: 1rem;

              &.active {
                border-color: palette(red);
                background: palette(red);
              }
            }

            .label {
              margin-left: 0.5rem;
              font-size: 0.9rem;
              text-transform: uppercase;
              line-height: 0.75rem;
              font-weight: 700;
              color: palette(gray);
            }
          }

          @media (max-width: 349px) {
            .profile-row {
              margin-bottom: 0.33rem;

              .profile-cell {
                width: 0.5rem;
                height: 0.5rem;
                margin-right: 0.33rem;
                border-width: 1.5px;
              }

              .label {
                font-weight: 400;
                font-size: 0.8rem;
                line-height: 0.5rem;
              }
            }
          }

          @media (min-width: 350px) and (max-width: 599px) {
            margin: 1.67rem 0 0;

            .profile-row {
              margin-bottom: 0.33rem;

              .profile-cell {
                width: 0.67rem;
                height: 0.67rem;
                margin-right: 0.33rem;
                border-width: 1.5px;
              }

              .label {
                font-size: 0.8rem;
                line-height: 0.67rem;
              }
            }
          }

          &.thumbnail {
            margin: 0;
            margin-top: 0.25rem;
            margin-left: 0.25rem;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: palette(white);
              border-radius: vars(border-radius);
              z-index: -1;
            }

            .profile-row {
              margin-bottom: 0.25rem;

              .profile-cell {
                width: 0.5rem;
                height: 0.5rem;
                margin-right: 0.25rem;
                border-width: 1.5px;
              }
            }
          }
        }

        .body {
          margin-top: 2rem;

          table {
            th {
              padding: 0.5rem 0;
              margin-bottom: -0.5rem;
              width: 6.75rem;
              color: palette(red);
              font-size: 0.75rem;
              line-height: 1.5rem;
              text-transform: uppercase;
            }

            td {
              p {
                &:first-child { margin-top: 0.5rem; }
                &:last-child { margin-bottom: 0.5rem; }
              }
            }
          }

          @include slice-to(sm) {
            table {
              display: block;

              tr {
                display: block;
                margin-bottom: 1rem;
              }

              th, td {
                padding: 0;
                display: block;
              }
            }
          }
        }
      }

      section.mission-briefing {
        padding-bottom: 0;

        .section-content {
          min-height: 16rem;

          &::after {
            height: 3rem;
          }
        }

        .section-toggle {
          padding: 0 0.75rem;
          position: absolute;
          left: 50%;
          bottom: -1rem;
          background: palette(white);
          color: palette(black);
          font-family: vars(font-body);
          font-size: 0.75rem;
          font-weight: 700;
          line-height: 2rem;
          text-transform: uppercase;
          border: 1px solid palette(border);
          border-radius: vars(border-radius, small);
          transform: translateX(-50%);
          transition: all 150ms ease;
          z-index: 100;
          min-width: 150px;

          .icon {
            margin-left: 0.5rem;
          }
        }

        &[data-accordion-open="true"] .section-toggle {
          .open   { display: block; }
          .closed { display: none;  }
        }

        &[data-accordion-open="false"] .section-toggle {
          background: palette(red);
          color: palette(white);
          border-color: palette(red);

          .open   { display: none;  }
          .closed { display: block; }
        }
      }
    }

    @include slice-from(md) {
      .buy-cluster {
        top: 2.5rem;

        @media screen and (min-height: 480px) {
          top: 4rem;
        }

        .floating-sale-thumbnail {
          height: 0;
          max-height: calc(100vh - 14rem);
          opacity: 0;
          transition: all 500ms $ease-out-quint;

          @media screen and (min-height: 480px) {
            max-height: calc(100vh - 18rem);
          }

          &.visible {
            opacity: 1;
          }

          &.padded {
            margin: 2rem 0;

            @media screen and (min-height: 480px) {
              margin: 4rem 0;
            }
          }

          .ui-sale-thumbnail,
          .legacy-photo,
          .legacy-photo img,
          .stacked-photo,
          .image-backdrop,
          .backdrop-photo,
          .bottle-photo {
            height: 100%;
          }

          .legacy-photo img {
            object-fit: cover;
          }

          .bottle-shot {
            height: 125%;
            opacity: 0;
            transition: all 500ms $ease-out-quint;

            &.visible {
              opacity: 1;
            }
          }
        }
      }
    }

    .mobile-buy-cluster {
      position: sticky;
      bottom: 0;
      background: palette(white);
      border-top: 1px solid palette(border);
      z-index: 101;

      .buy-cluster {
        margin: 0;
        padding: 0;

        .register-text {
          font-size: 1rem;
          margin: 1rem 0;
        }

        .ui-button {
          &.large {
            font-size: 1.25rem;
            padding: 0 1.25rem;
            line-height: 3rem;
            margin: -1.5rem 0 0.3rem;
          }

          &.register {
            margin: 0 0 1rem;
          }
        }

        .expires {
          display: none;
        }

        .pricing {
          padding: 0 0 0.5rem;
        }

        .price {
          .amount {
            font-size: 1.3rem;
            line-height: 1.5rem;
          }
        }

        .avg-price {
          .amount {
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}

// style bottle zoom modal
main#offer-page + .ui-modal-root.bottle-zoom-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: palette(black, base, 50%);
  z-index: 1000;

  .ui-modal {
    background: none;
    box-shadow: none;
  }

  .modal-header {
    background: none;

    .modal-toggle {
      margin: 0.5rem;
      padding: 1rem;
      background: palette(black, base, 75%);
      border-radius: 50%;

      svg {
        color: palette(white);
      }
    }
  }

  .modal-body {
    overflow: hidden;
  }

  .zoom-thumbnail {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .ui-sale-thumbnail {
      width: 100%;

      &.thumbnail-stacked {
        margin-bottom: 2rem;
      }
    }

    .stacked-photo {
      width: 100%;
      margin: 2rem 0;

      .image-backdrop {
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 100%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .bottle-shot {
        height: calc(100% + 4rem);

        img.bottle-photo {
          height: 100%;
        }
      }
    }

    @include slice-to(xs) {
      .stacked-photo {
        width: 100%;
        height: calc(100vw - 4rem);
        margin: 2rem 0;

        .image-backdrop {
          position: absolute;
          left: -2rem;
          right: -2rem;
          bottom: -4rem;
          height: calc(100% + 2rem);
          width: calc(100% + 4rem);
          max-width: none;
          max-height: none;
          overflow: visible;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: vars(border-radius, large) vars(border-radius, large) 0 0;
          }
        }

        .bottle-shot {
          height: calc(100% + 4rem);

          img.bottle-photo {
            height: 100%;
          }
        }
      }
    }

    .legacy-photo {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
}
