@use 'stylesheets/setup' as *;

.admin-sale-nav {
  margin: -1rem 0 1rem;
  padding: 0.75rem 1rem 0;
  background: palette(red, light);
  border: 1px solid rgba(palette(red), 0.25);
  border-radius: vars(border-radius);
  z-index: 100;

  @include slice-from(lg) {
    margin-top: 0;
    position: absolute;
    top: -2.5rem;
    left: 0;
    right: 0;
  }

  .label {
    color: palette(red);
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
  }
}

@include slice-from(lg) {
  .admin-sale-nav-spacer {
    width: 100%;
    height: 4rem;
  }
}
