@use 'stylesheets/setup' as *;

.buy-cluster {
  position: sticky;
  top: 1rem;
  margin-top: 1rem;
  padding: 0 0 3rem;

  .register-text {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }

  .mobile-redirect-button {
    text-align: center;
    margin: 1rem 0 0;
  }

  .ui-button.large {
    font-size: 2rem;
  }

  .expires {
    margin: 0 0 0.5rem;
    text-align: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: palette(gray, dark);

    .timing {
      font-weight: 700;
    }
  }

  .pricing {
    padding-bottom: 2rem;

    .redacted {
      .amount-placeholder {
        display: inline-block;
        margin: 0.25rem 0;
        height: 1.5rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
        background: palette(gray, light);
        color: palette(gray, light);
      }

      .label {
        font-size: 0.75rem;
        font-weight: 700;
        color: palette(gray);
        text-transform: uppercase;
        color: palette(gray);
      }
    }

    .price {
      .amount {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2rem;
      }

      .percent-off {
        font-size: 0.75rem;
        font-weight: 700;
        color: palette(gray);
        text-transform: uppercase;
        color: palette(red);
      }
    }

    .avg-price {
      text-align: right;

      .amount {
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 2rem;
        text-decoration: line-through;
        color: palette(red);
      }

      .label {
        font-size: 0.75rem;
        font-weight: 700;
        text-transform: uppercase;
        color: palette(gray, dark);
      }
    }
  }
}
